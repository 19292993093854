:root {
    --primary: #EE6C4D;
    --secondary: #0D61D3;
    --primary-text: #505050;
    --secondary-text: #F8F8F8;
    --button-unselected: #ACACAC;
    --common-background: #F8F8F8;
    --green: #2EF321;
    --red: #FF0000;
    --secondary-background: #ecf2fa;
    --primary-background: #F7EBE9;
}