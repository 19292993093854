.Login-page {
  display: flex;

}

.login {
  justify-content: center;
  align-items: center;
}

.login-form {
  background-color: var(--common-background);
  max-width: 50%;
}


.input-field {
  position: relative;
  left: -15%;

}

input {
  width: 40vw;
  padding: 20px;
  margin: 20px 20px;
  border: 1px solid #646060;
  border-radius: 30px;
  padding-left: 21%;
  font-style: 30px;
  font-size: 16px;
}

button {

  padding: 12px 80px;
  background-color: var(--secondary);
  color: var(--common-background);
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 20px;
  margin-left: -8%;
  margin-top: 3%;
}

button:hover {
  background-color: var(--secondary);
}

.welcome {
  /* text-align: center; */
  font-size: 400%;
  color: var(--secondary);
  margin-top: 1vh;
  margin-bottom: 1vh;
  margin-left: 34%;
}

.logo {
  width: 20vw;
  margin-left: 30%;
  padding-top: 5vh;

}

.text {
  width: 90%;
  margin-top: 5%;
  padding-left: 4%;
}

.bg {
  height: 81vh;
  width: 100%;
  padding-bottom: 5vh;
}

.icon {
  position: absolute;
  top: 35%;
  left: 14%;
}

.left {
  background: linear-gradient(to top, rgb(70, 126, 210), transparent);
  margin-right: 8%;
  padding-left: 2vw;
  height: 100vh;
}


input::placeholder {
  font-size: 16px;
  font-weight: 200;
}

.select-field {
  margin: 5%;
  margin-left: 50%;
}

.select {
  padding: 10px;
  background: var(--common-background);
  border: none;
  outline: none;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* Ensure the overlay is above other content */
}